@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes slow-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}