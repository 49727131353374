/* .no-tailwind-base *, .no-tailwind-base *::before, .no-tailwind-base *::after {
  all: revert;
} */

.ql-editor ol {
  padding-left:1em
} 



.ql-editor li.ql-indent-1:not(.ql-direction-rtl){padding-left:3em}
.ql-editor li.ql-indent-2:not(.ql-direction-rtl){padding-left:5em}
.ql-editor li.ql-indent-3:not(.ql-direction-rtl){padding-left:7em}
.ql-editor li.ql-indent-4:not(.ql-direction-rtl){padding-left:9em}
.ql-editor li.ql-indent-5:not(.ql-direction-rtl){padding-left:11em}
.ql-editor li.ql-indent-6:not(.ql-direction-rtl){padding-left:11em}
.ql-editor li.ql-indent-7:not(.ql-direction-rtl){padding-left:11em}
.ql-editor li.ql-indent-8:not(.ql-direction-rtl){padding-left:11em}

.ql-font-arial {
  font-family: 'Arial', sans-serif;
}
.ql-font-times-new-roman {
  font-family: 'Times New Roman', serif;
}
.ql-font-courier {
  font-family: 'Courier', monospace;
}
.ql-font-georgia {
  font-family: 'Georgia', serif;
}
.ql-font-helvetica {
  font-family: 'Helvetica', sans-serif;
}
.ql-font-verdana {
  font-family: 'Verdana', sans-serif;
}

.ql-font-roboto {
  font-family: 'Roboto', sans-serif;
}

.ql-font-Tiny5 {
  font-family: 'Tiny5', sans-serif;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options{
  border-color:#ccc;
  overflow-y: scroll;
  height: 20vh;
}


ql-snow .ql-picker.ql-font .ql-picker-label[data-value='']::before,
    .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='']::before
    {
      content: 'Default';
    }
    .ql-snow .ql-picker.ql-font .ql-picker-label[data-value='Tiny5']::before,
    .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='Tiny5']::before {
      content: 'Tiny5';
      font-family: 'Tiny5', sans-serif;
    }
    .ql-snow .ql-picker.ql-font .ql-picker-label[data-value='roboto']::before,
    .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='roboto']::before {
      content: 'Roboto';
      font-family: 'Roboto', sans-serif;
    }
    .ql-snow .ql-picker.ql-font .ql-picker-label[data-value='times-new-roman']::before,
    .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='times-new-roman']::before
    {
      content: 'Times NR';
      font-family: 'Times New Roman';
    }
    
    .ql-snow .ql-picker.ql-font .ql-picker-label[data-value='arial']::before,
    .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='arial']::before
    {
      content: 'Arial';
      font-family: 'Arial';
    }
    .ql-snow .ql-picker.ql-font .ql-picker-label[data-value='courier']::before,
    .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='courier']::before
    {
      content: 'Courier';
      font-family: 'Courier';
    }
    .ql-snow .ql-picker.ql-font .ql-picker-label[data-value='georgia']::before,
    .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='georgia']::before
    {
      content: 'Georgia';
      font-family: 'Georgia';
    }
    .ql-snow .ql-picker.ql-font .ql-picker-label[data-value='helvetica']::before,
    .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='helvetica']::before
    {
      content: 'Helvetica';
      font-family: 'Helvetica';
    }
    .ql-snow .ql-picker.ql-font .ql-picker-label[data-value='verdana']::before,
    .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='verdana']::before
    {
      content: 'Verdana';
      font-family: 'Verdana';
    }

